import React from "react";
import Header from "../../../components/Header/Header"
import SearchResultPage from "../../../templates/search-results-template";
import Footer from "../../../components/Footer/Footer"
import {
    parseSearchUrl,
    savedSearchParams,
    propertyH1
} from "../../../components/common/site/utils";

const SearchResultLet = (props) => {

    const fullpath = props.location.pathname
    const location = props.location
    const fullpathone = props.location
    const searchParams = parseSearchUrl("lettings", fullpath);

    return (
        <React.Fragment>
            <Header />
            <SearchResultPage locationname="/landlords/let-gallery/" pcategorytype="residential" ptype="lettings" ptypetag="let" pstatustype="Let" fullpathone={fullpathone} fullpathname={fullpath}/>
            <Footer />
            
        </React.Fragment>
    );
};

export default SearchResultLet;
